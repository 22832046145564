const mapToShopify = lineItems => {
  const mappedItems = lineItems.map(lineItem => {
    // Map the relevant parts of each line item
    const item = {
      merchandiseId: atob(lineItem.product.ecomVariantHandler),
      quantity: lineItem.quantity,
      ...(lineItem.customData &&
        lineItem.customData.sellingPlanId && {
          sellingPlanId: lineItem.customData.sellingPlanId
        })
    }
    // Map any customData to the 'customAttributes' format Shopify expects
    for (let propName in lineItem.customData) {
      if (lineItem.customData[propName] == null) {
        continue
      }

      // TODO: remove this underscore lookup mapping once BE supports underscored custom attributes (primarily relevant to gift cards)
      let hiddenPropName
      if (
        [
          "sellingPlan",
          "orderIntervalFrequency",
          "orderIntervalUnitType",
          "adjustmentValue"
        ].some(item => propName.startsWith(item))
      ) {
        hiddenPropName = "__" + propName
      }

      if (!item.attributes) item.attributes = []
      item.attributes.push({
        key: hiddenPropName || propName,
        value: lineItem.customData[propName].toString()
      })
    }
    return item
  })

  // Stringify to GraphQL Format
  return JSON.stringify(mappedItems).replace(/"([^(")"]+)":/g, "$1:")
}

const checkoutMutation = (lineItems, email, buuid, cartId) => `
  mutation {
    cartCreate(input: {
      attributes: [
        { key: "cartId", value: "${cartId}" }
        ${buuid ? ',{ key: "buuid", value: "' + buuid + '" }' : ""}
      ]
      lines: ${mapToShopify(lineItems)},
      ${email ? 'buyerIdentity: { email: "' + email + '"},' : ""}
    }) {
      cart {
        id
        checkoutUrl
        buyerIdentity {
          email
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export default {
  checkout: function(lineItems, locale, email, buuid, cartId, queryParams) {
    let storeURL,
      storeKey = ""

    if (locale == "en-GB") {
      storeURL = process.env.VUE_APP_UK_SHOPIFY_STORENAME
      storeKey = process.env.VUE_APP_UK_STOREFRONT_KEY
    } else if (locale == "en-US") {
      storeURL = process.env.VUE_APP_US_SHOPIFY_STORENAME
      storeKey = process.env.VUE_APP_US_STOREFRONT_KEY
    }

    fetch(`${storeURL}/api/2021-10/graphql.json`, {
      method: "POST",
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": storeKey
      },
      body: checkoutMutation(lineItems, email, buuid, cartId)
    })
      .then(response => response.json())
      .then(response => {
        if (response && response.data) {
          const checkoutUrl = new URL(response.data.cartCreate.cart.checkoutUrl)
          const email = response.data.cartCreate.cart.buyerIdentity.email

          Object.entries(queryParams).forEach(param => {
            checkoutUrl.searchParams.set(param[0], param[1])
          })

          if (email) {
            checkoutUrl.searchParams.set("checkout[email]", email)
          }

          window.location = checkoutUrl
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
}
