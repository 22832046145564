export const scriptElementTypes = {
  get DEFAULT() {
    return {
      type: "DefaultElement",
      model: () => ({
        id: String,
        type: String
      })
    }
  },
  get MESSAGE() {
    return {
      type: "MessageElement",
      model: () => ({
        ...this.DEFAULT.model(),
        conversationElement: Object,
        duration: Number
      })
    }
  },
  get USER_INPUT() {
    return {
      type: "InputElement",
      model: () => ({
        ...this.MESSAGE.model(),
        responseSequence: Object
      })
    }
  },
  get ACTION() {
    return {
      type: "ActionElement",
      model: () => ({
        ...this.DEFAULT.model(),
        action: String,
        payload: Object
      })
    }
  },
  get DECISION() {
    return {
      type: "DecisionElement",
      model: () => ({
        ...this.DEFAULT.model(),
        query: String,
        responseSequence: Object
      })
    }
  }
}

Object.freeze(scriptElementTypes)

export const scriptValidator = function(script, referenceObject) {
  let _isValid = true

  for (let i = 0; i < script.length; i++) {
    let element = script[i]
    if (!element.type) return false

    let _type = Object.entries(referenceObject)[
      Object.entries(referenceObject).findIndex(
        type => type[1].type === element.type
      )
    ][0]

    if (!_type) _isValid = true

    Object.entries(referenceObject[_type].model()).forEach(prop => {
      // if there's no matching prop, or the type of the prop doesn't match the type declared in scriptElementTypes
      if (
        // ( prop is missing OR
        (!element[prop[0]] ||
          // prop type doesnt match reference type ) AND
          typeof element[prop[0]] !== typeof prop[1]()) &&
        // it's not null
        element[prop[0]] !== null
      ) {
        console.warn(
          "Type check failed on ",
          element.id,
          prop[0],
          "  ---->  ",
          element[prop[0]]
        )
        _isValid = false
      }
    })
  }

  return _isValid
}
