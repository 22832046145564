export default {
  scriptElements: [
    {
      id: "chat_launch_01",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Let's get you connected to someone who can help!"
      },
      duration: 2000
    },
    {
      id: "chat_launch_02",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text:
          "Type your question and one of our agents will get back to you just as soon as they can"
      },
      duration: 2000
    },
    {
      id: "redirect_to_cs",
      type: "ActionElement",
      action: "INIT_ASYNC_CHAT",
      payload: null
    }
  ]
}
